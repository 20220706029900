.container {
  margin: auto;
  max-width: 900px;
  padding: 20px;
  padding-top: 100px;
   text-align: center;
}

.banners-list {
  margin-top: 20px;
  justify-content: center;
  
}

.banner-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-actions {
  display: flex;
  justify-content: center;
}

.delete-button {
  color: #f44336; /* Red color for delete button */
}

.delete-button:hover {
  color: #d32f2f; /* Darker red on hover */
}
