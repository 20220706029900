.update-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.profile-card {
  text-align: center;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.upload-photo-button {
  margin-top: 10px;
}

.update-form-card {
  padding: 20px;
}

.update-form {
  width: 100%;
}

.update-form .MuiGrid-item {
  padding: 10px;
}
