.container {
  margin: 0 auto;
  padding: 20px;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar input {
  width: 300px;
  padding: 10px;
  border: 45%;
  border-radius: 4px 0 0 4px;
  outline: 45%;
}

.search-bar button {
  padding: 10px;
  background-color: lightgreen;
  border: 30%;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: darkgreen;
}

.search-bar button svg {
  color: white;
}
  
  .table-wrapper {
    overflow-x: auto;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .responsive-table th {
    background-color: #f2f2f2;
  }
  
  .responsive-table .even {
    background-color: #f9f9f9;
  }
  
  .responsive-table .odd {
    background-color: #ffffff;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    background-color: lightgreen;
    z-index: 10;
  }
  
  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    padding: 20px;
    box-shadow: 30px;
  }
  
  button {
    margin-right: 10px;
  }
  